<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-card no-body>
          <h4 class="m-2">Event Team</h4>
          <b-table 
            ressmallonsive="sm" 
            :items="items"
            :fields="fields"
          >
            <template #cell(name)="data">
              <div class="d-flex flex-row">
                <b-avatar v-if="data.item.image !== null"
                  class="mr-50 d-flex"
                  :src="`${data.item.image}`"
                /> 
                <b-avatar v-else
                  class="mr-50 d-flex"
                  variant="primary"
                  :text="data.item.name | initial"
                />
                <div class="d-flex flex-column">
                  <p class="m-0">{{ data.item.name }}</p>
                  <small class="text-muted">
                    {{ data.item.email }}
                  </small>
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="demoteTeam(data.item.id)">
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Demote</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardBody,
    BTable, BAvatar, BDropdown, BDropdownItem, BButton, BModal,
    BFormGroup, BFormInput, BInputGroup, BInputGroupAppend
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody,
      BTable, BAvatar, BDropdown, BDropdownItem, BButton, BModal,
      BFormGroup, BFormInput, BInputGroup, BInputGroupAppend
    },
    directives: {
      Ripple,
    },
    data() {
      return {
				fields: [
					{ key: 'name', label: 'Name', sortable: true },
					{ key: 'phone', label: 'Phone', sortable: true },
					{ key: 'actions', label: 'Actions' }
				],
        items: [],

        user_keyword: null,
        user_result: [],

        active_role: null
      }
    },
		filters: {
			initial: function (val) {
				var result = "";
				let tokens = val.split("")

				if(tokens.length == 1) {
					result = tokens[0].substring(0,1).toUpperCase()
				} else {
					for(let i=0; i<2; i++){
						result += tokens[i].substring(0,1).toUpperCase()
					}
				}

				return result
			}
		},
    created() {
      this.active_role = localStorage.getItem('role')
      this.getDataTeam()
      this.checkAuth()
    },
    methods: {
      async getDataTeam() {
        let cid = localStorage.getItem('client_id')
				
				let dataAPI = null
        if(this.active_role == 'corporate') {
          const config = {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
          dataAPI = await this.$axios.get(`${cid}/event/team`, config)
        }

        let member = dataAPI.data.data
        this.items = member
      },
      demoteTeam(id) {
        let cid = localStorage.getItem('client_id')
				
				this.$bvModal
					.msgBoxConfirm('Are you sure to demote this user?', {
						title: 'Demote confirmation',
						size: 'sm',
						okVariant: 'danger',
						okTitle: 'Demote',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
							this.$axios.delete(`${cid}/event/demote-team/${id}`)
								.then(res => {
									this.items = []
									this.getDataTeam()
								})
						}
				})
      }
    }
  }
</script>

<style>
  .search-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
  }
</style>